<template>
  <div class="left-head">
    <div class="logo">
      <router-link to="/" tag="a">
        <img src="@/assets/images/logo.png" />
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: "LogoSite",
  data() {
    return {};
  },
  methods: {},
  components: {},
};
</script>
