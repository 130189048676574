<template>
  <!--search-->
  <div class="search_area">
    <div class="search-icon" @click="showSearch()">
      <div class="search-icons"></div>
    </div>

    <transition mode="out-in" name="fade">
      <div class="searchbox" :class="{ show: show }" v-if="show == true">
        <div class="container">
          <span class="close" @click="hideSearch()"
            ><i class="fas fa-times"></i
          ></span>
          <form @submit.prevent="searchMethod(), hideSearch()">
            <div class="input-group">
              <input
                type="text"
                v-model="search.text"
                class="form-control"
                placeholder="Search"
              />
              <div class="input-group-prepend d-none">
                <button class="input-group-text" id="basic-addon1">
                  <div class="search-icons"></div>
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </transition>

    <transition mode="out-in" name="fade">
      <div
        class="overlay"
        @click="hideSearch()"
        :class="{ disblock: show }"
      ></div>
    </transition>
  </div>
  <!--search-->
</template>

<script>
export default {
  data() {
    return {
      show: false,
      search: {
        text: "",
      },
    };
  },
  methods: {
    // search method
    searchMethod() {
      if (this.search.text !== "") {
        this.$router.push({
          name: "search",
          params: { text: this.search.text },
        });
      }
      this.search.text = "";
    },
    showSearch() {
      this.show = true;
    },
    hideSearch() {
      this.show = false;
    },
  },
};
</script>
